import React from 'react'
import '../assets/scss/main.scss';

import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet';
import Footer from '../components/FooterEvent'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Register from '../components/Register.jsx';

import HeaderEvent from '../components/HeaderEvent';
import momentum from '../images/momentum.png';
import FlipCard from '../components/FlipCard.jsx';
import logo from '../assets/icons/BNT Logo v1 - Transparent.png';
import favicon from '../assets/images/favicon.png';

class EventsPage extends React.Component {
  render() {
    return (
      <span>
        <div>
        <Helmet>
          <title>Events</title>
          <link rel="shortcut icon" type="image/png" href={favicon}/>
       </Helmet>
        <HeaderEvent mainImage={logo} />
        <div id="header" className="events__pageHeader">
          <div> 
            MOMENTUM 
            <br />
            LUNCH & LEARN 
          </div>
        </div>

        <ParallaxProvider>
        <div id="main" className="eventsContainer">
          <section id="content" className="main events">
            <div className="events__main">

                <div className="events__main_img">
                  <img src={momentum} />
                </div>
              <div className="events__main_info">
                  <h2 className="events__main_info_location">NORTH ROYALTON</h2>
                  <p>
                    <div className="events__main_info_row">
                      <div>WHEN:</div>
                      <div>Thursday, August 7th 2019 | <strong>12:00pm - 3:00pm</strong></div>
                    </div>
                    <div className="events__main_info_row">
                      <div>WHERE:</div>
                      <div>12316 York Road, North Royalton, OH 44133</div>
                    </div>
                    <div className="events__main_info_row">
                      <div>WHAT:</div>
                      <div>
                        Lunch: 12:00 - 1:00 <br />
                        Momementum Information Session: 1:00 - 3:00
                      </div>
                    </div>
                  </p>
                  <div className="events__main_register">
                    <a href="#registerPage">
                      <button className="btn draw-border">
                        Register!
                      </button>
                    </a>
                  </div>
               </div>
            </div>

            <div className="events__description">

              <Parallax
                offsetYMax={25}
                offsetYMin={-25}
              >
                <h2>
                  <span >Please join us for the <br /></span>
                  <span style={{fontWeight: 600}}>Business Network Team / <span style={{color:"#c1d52e"}}>Momentum</span><br /></span>
                  <span >Lunch + Learn!</span>
                </h2>
              </Parallax>
            </div>

          <section id="content" className="main">

          <h1 className="registerPage__header" >Event Registration</h1>
            <div id="registerPage">
              <div className="registerPage__info"> 
                <div className="registerPage__info_line">
                  <div className="registerPage__info_title">Momentum Party: </div>
                  <div>
                  </div>
                </div>
                  <div className="registerPage__info_line"> 
                  <div className="registerPage__info_title">Time:</div>
                <div>August 7th, 2019 12:00 - 3:00 PM in <strong> EST </strong></div>
                </div>
              </div>
            <Register />
            </div>
          </section>

          </section>
        </div>

      </ParallaxProvider>
      </div>
      <Footer />
      </span>
    )
  }
}

export default EventsPage

export const pageQuery = graphql`
  query EventsQuery{
    site {
      siteMetadata {
        title
      }
    }
  }
`

